import { HttpHeaders } from '@angular/common/http';

export const createHttpHeaders = (headersObject: {
  [key: string]: string;
}): HttpHeaders => {
  let headers = new HttpHeaders();

  for (const key in headersObject) {
    headers = headers.append(key, headersObject[key]);
  }

  return headers;
};
