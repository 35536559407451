export enum SortDirection {
  NONE = 'none',
  ASC = 'asc',
  DESC = 'desc',
}
export interface SortConfig {
  column: string;
  direction: SortDirection;
}

export type SortTracker = {
  [key: string]: SortDirection;
};
